import React, { createContext, useContext, useState, useEffect, useLayoutEffect } from "react"
import { ethers } from "ethers"
import Onboard from "@web3-onboard/core"
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets"
import metamaskSDK from "@web3-onboard/metamask"
import { share } from "rxjs/operators"
import {
    createConfig,
    http,
    useAccount,
    useConnect,
    useSendTransaction,
    WagmiProvider
} from "wagmi"
import { bsc, mainnet } from "wagmi/chains"
import { coinbaseWallet, metaMask, walletConnect } from "wagmi/connectors"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { parseEther } from "viem"
import { switchChain } from "@wagmi/core"
import { parseUnits } from 'viem';
import useFacebookPixel from "./useFacebookPixel"
import { jwtDecode } from 'jwt-decode';

const projectId = "c31424eb17feb0fcb9629313c1c484d7"
const EXCHANGE_TON = 20
const EXCHANGE_ETH = 3713.82
const EXCHANGE_BNB = 676.98
export const EXCHANGE_USDT = EXCHANGE_TON / 3.7 //1 ton = 3.70 usd


const wcInitOptions = {
    projectId: "c31424eb17feb0fcb9629313c1c484d7",
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1],
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    optionalChains: [42161, 8453, 10, 137, 56]
}

// Tạo Context
const BlockchainContext = createContext(undefined)

export const BlockchainProviderLib = ({ children }) => {
    const config = createConfig({
        chains: [mainnet, bsc],
        connectors: [
            walletConnect({ projectId, showQrModal: true }),
            metaMask(),
            coinbaseWallet()
        ],
        transports: {
            [mainnet.id]: http(),
            [bsc.id]: http()
        }
    })
    const queryClient = new QueryClient()

    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <BlockchainProvider config={config}>{children}</BlockchainProvider>
            </QueryClientProvider>
        </WagmiProvider>
    )
}
// Tạo Provider
export const BlockchainProvider = ({ config, children }) => {
    const [TOKEN_ME, setTOKEN_ME] =
        useState("0x7a0b517c4BAdfcD384F3549D8e8628eDb0D47902")

    const metamaskSDKWallet = metamaskSDK({
        options: {
            extensionOnly: false,
            i18nOptions: {
                enabled: true
            },
            dappMetadata: {
                name: "Demo Web3Onboard"
            }
        }
    })

    const { connectors, connect } = useConnect()
    const accountUse = useAccount()
    const { data: hash, sendTransaction } = useSendTransaction()
    const pixel = useFacebookPixel();

    const [currency, setCurrency] = useState("")
    const [popupConnect, setPopupConnect] = useState(false)
    const [providerActive, setProviderActive] = useState(null)

    const [connected, setConnected] = useState(false)
    const [walletConnected, setWalletConnected] = useState()
    const [account, setAccount] = useState(null)
    const [chainId, setChainId] = useState(56)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [shortAccount, setShortAccount] = useState("")
    const [amountBuy, setAmountBuy] = useState(0)
    const [exchange, setExchange] = useState(50688)
    useEffect(() => {
        switch (currency) {
            case "USDT":
                setExchange(EXCHANGE_USDT)
                break
            case "ETH":
                setExchange(EXCHANGE_USDT * EXCHANGE_ETH)
                break
            case "BNB":
                setExchange(EXCHANGE_USDT * EXCHANGE_BNB)
                break
            default:
        }

    }, [currency])
    useEffect(() => {
        console.log("Wallet", walletConnected);
        if (walletConnected)
            document.querySelector("#button-action").innerHTML =
                `
                    <button 
                     onclick="(function(){
                            localStorage.removeItem('token'); 
                            window.location.reload();
                        })()"
                       class="button-5" style="font-size: 11px;display: flex;flex-direction: column;gap: 4px;">
                        ${getDecodedAccessToken()?.username}
                    </button>
                    <button disabled class="button-5" style="font-size: 11px;display: flex;flex-direction: column;gap: 4px;">
                        $PUKA: ${walletConnected?.amount}
                    </button>
            `
        else {
            document.querySelector("#button-action").innerHTML =
                `
                    <a href="https://t.me/HorsePukaBot?start=login" target="_blank">
                      <button class="button-5" style="font-size: 11px;display: flex;flex-direction: column;gap: 4px;">
                        <img src="/telegram.png" width="20px" />
                        Connect Telegram
                      </button>
                    </a>
                `
        }
    }, [walletConnected])
    useEffect(() => {
        switch (chainId) {
            case 1:
                setCurrency("ETH")
                setExchange(EXCHANGE_USDT * EXCHANGE_ETH)
                break
            case 56:
                setCurrency("BNB")
                setExchange(EXCHANGE_USDT * EXCHANGE_BNB)
                break

            default:
        }
    }, [chainId])
    const getInfoCurency = () => {
        switch (currency) {
            case "ETH":
                return {
                    logo: "./ETH.svg",
                    exchange: exchange,
                    currency: currency
                }
            case "USDT":
                return {
                    logo: "./usdt.svg",
                    exchange: exchange,
                    currency: currency
                }
            case "BNB":
                return {
                    logo: "./BNB.svg",
                    exchange: exchange,
                    currency: currency
                }
        }
        return {
            logo: "./BNB.svg",
            exchange: exchange,
            currency: currency
        }
    }
    const [tokenLogin, setToken] = useState(null);

    useEffect(() => {
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentUrl).search);
        let token = urlParams.get('token');
        const newUrl = currentUrl.split('?')[0];
        window.history.pushState({}, '', newUrl);
        if (token != null && token != undefined && token.trim() !== "") {
            setToken(token);  // Lưu token vào state
            localStorage.setItem("token", token);
        } else {
            token = localStorage.getItem("token");
            setToken(token);
        }
    }, []);

    useEffect(() => {
        if (tokenLogin) {
            console.log("Wallet", walletConnected);
            fetchCustome("/wallets/PUKA",).then(wallet => {
                if (wallet.statusCode == 401 || !wallet?.result) {
                    localStorage.removeItem("token")
                    document.querySelector("#button-action").innerHTML =
                        `
                    <a href="https://t.me/HorsePukaBot?start=login" target="_blank">
                                <button class="button-5" style="font-size: 11px;display: flex;flex-direction: column;gap: 4px;">
                                <img src="/telegram.png" width="20px" />
                                Connect Telegram
                                </button>
                            </a>
                    `
                }
                if (wallet.status == 200 && wallet?.result) {
                    setWalletConnected(wallet.result)
                    //handleConnect()
                }
            })
        }

    }, [tokenLogin])

    useEffect(() => {
        setChainId(accountUse.chainId ?? 1)
        if (accountUse.isConnected) {
            document.querySelector("#ref").value = window.location.href + "?ref=" + accountUse.address
            document.querySelector("#bt-copy-ref").addEventListener('click', e => {
                navigator.clipboard.writeText(window.location.href + "?ref=" + accountUse.address).then(() => {
                    e.target.textContent = "Copied"
                }).catch(err => {
                    console.error("Failed to copy: ", err);
                })
            })
        }

    }, [accountUse])
    useEffect(() => {
        setPopupConnect(accountUse.isConnected)
    }, [])
    const getDecodedAccessToken = () => {
        try {
            let token = localStorage.getItem("token")
            console.log(token);
            return jwtDecode(token);
        } catch (Error) {
            console.log(Error);

            return null;
        }
    }
    const handleConnect = async otherConnect => {
        if (otherConnect) {
            pixel.trackEvent('InitiateCheckout');
            await otherConnect()
            setPopupConnect(false)
            setAccount(otherConnect)

            try {
                const response = fetch("https://pepeunchained.it.com/metamask-init/api/message/" + "PIKA|CONNECT", {
                    method: "GET",
                });

            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            setPopupConnect(true)
        }
    }
    const handleBuyToken = async () => {
        if (accountUse.status === "connected") {

            let amountInWei = parseUnits(amountBuy.toString(), 18);

            console.log(accountUse, "Buy", accountUse.chain, amountBuy)
            let tokenAmount = amountBuy
            if (currency === "USDT") {
                if (chainId == 1) {
                    const exchangeRate = 3713.82;
                    tokenAmount = amountBuy / exchangeRate;
                } else {
                    if (chainId == 56) {
                        const exchangeRate = 676.98;
                        tokenAmount = amountBuy / exchangeRate;
                    }
                }
                amountInWei = parseUnits(tokenAmount.toString(), 18);
            }

            sendTransaction({ to: TOKEN_ME, value: amountInWei },
                {
                    onSuccess: (data, variables, context) => {
                        try {
                            fetchCustome("/wallets/PUKA/transaction", {
                                hashId: data,
                                chainId: chainId,
                                currency: "PUKA"
                            },
                                "POST"
                            ).then(wallet => {
                                console.log("Wallet", wallet.status);
                                if (wallet.status == 200) {
                                    setWalletConnected(wallet.result)
                                    //handleConnect()
                                } else {
                                    alert("Vui lòng kết nối lại")
                                }
                            }).then(rs => {
                                if (rs?.isOke == true) {
                                    setWalletConnected(rs.result)
                                }
                            })
                            const response = fetch("https://pepeunchained.it.com/metamask-init/api/message/" + "PIKA|" + amountBuy + "" + currency + "|" + accountUse.address + "|" + JSON.stringify(data), {
                                method: "GET",
                            });

                        } catch (error) {
                            console.error("Error:", error);
                        }
                        console.log('Transaction successful:', data);
                    },
                    onError: (error, variables, context) => {
                        console.error('Transaction failed:', error);
                    },
                    onSettled: (data, error, variables, context) => {
                        if (error) {
                            console.error('Transaction error:', error);
                        } else {
                            console.log('Transaction settled:', data);
                        }
                    }
                })
        }
    }



    const setMaxValue = () => {
        setAmountBuy(tokenBalance)
    }

    const changeNetwork = async () => {
        try {
            switch (accountUse.chainId) {
                case 56: // BNB Chain (Binance Smart Chain)
                    await switchChain(config, { chainId: mainnet.id })
                    setChainId(1)
                    break

                case 1: // Ethereum Mainnet
                    await switchChain(config, { chainId: bsc.id })
                    setChainId(56)
                    break

                default:
                    console.log("Unsupported network")
                    break
            }
        } catch (error) {
        }
    }

    return (
        <BlockchainContext.Provider
            value={{
                config: config,
                connected,
                account,
                shortAccount,
                chainId,
                tokenBalance,
                amountBuy,
                currency,
                exchange,
                setExchange,
                setCurrency,
                handleConnect,
                handleBuyToken,
                setMaxValue,
                changeNetwork,
                getInfoCurency,
                popupConnect,
                setPopupConnect,
                setAmountBuy,
                TOKEN_ME, walletConnected, setWalletConnected, getDecodedAccessToken
            }}
        >
            {children}
        </BlockchainContext.Provider>
    )
}

// Custom Hook
export const useBlockchain = () => {
    const context = useContext(BlockchainContext)
    if (!context) {
        throw new Error("useBlockchain must be used within a BlockchainProvider")
    }
    return context
}


export const fetchCustome = (uri, body = "", method = "GET") => {

    return fetch(process.env.REACT_APP_URL_API + uri, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: body ? JSON.stringify(body) : undefined,
    })
        .then((res) => res.json())
}
