import { useState, useEffect } from 'react';

const useCountdown = () => {
    // Đặt ngày đích ban đầu là 30 phút kể từ thời điểm hiện tại
    const initialTargetDate = 1737551462607
    const [targetDate, setTargetDate] = useState(initialTargetDate);

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const difference = targetDate - now;
            if (difference <= 0) {
                // Reset targetDate sang 30 phút tiếp theo
                const nextTargetDate = new Date(now + 30 * 24 * 60 * 60 * 1000).getTime(); // Thêm 30 ngày vào thời gian hiện tại

                setTargetDate(nextTargetDate); // Cập nhật lại targetDate
            } else {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    return timeLeft;
};

export default useCountdown;
