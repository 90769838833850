import logo from './logo.svg';
import './App.css';
import { BlockchainProviderLib, EXCHANGE_USDT, fetchCustome, useBlockchain } from './context/BlockchainContext';
import PopupConnect from './components/PopupConnect';
import { disconnect, getAccount } from '@wagmi/core'
import { useAccount } from 'wagmi';
import useCountdown from './context/CountDown';
import useFacebookPixel from './context/useFacebookPixel';
import { useEffect, useState } from 'react';

function Main() {
  const {
    connected,
    shortAccount,
    chainId,
    tokenBalance,
    currency,
    exchange,
    setCurrency,
    handleConnect,
    handleBuyToken,
    setMaxValue,
    changeNetwork,
    getInfoCurency,
    setPopupConnect,
    popupConnect,
    config,
    amountBuy,
    setAmountBuy,
    TOKEN_ME, walletConnected, setWalletConnected
  } = useBlockchain()
  const [amountRevice, setAmountRevice] = useState(0);
  const account = useAccount()
  const { connector } = getAccount(config)
  const { days, hours, minutes, seconds } = useCountdown();
  const pixel = useFacebookPixel();
  useEffect(() => {
    setAmountRevice(Math.floor(Number(amountBuy) * exchange));
  }, [amountBuy, exchange])



  const handleConnectPre = async () => {
    if (account.status === "connected") {
      await disconnect(config, {
        connector,
      })
      localStorage.removeItem("token")
      return
    }
    fetchCustome("/wallets/PUKA",).then(wallet => {
      console.log("Wallet", wallet.statusCode);
      if (wallet.statusCode == 401) {
        localStorage.removeItem("token")
        const url = "https://t.me/HorsePukaBot?start=login";
        window.open(url, "_blank");
      }
      if (wallet.status == 200) {
        setWalletConnected(wallet.result)
        handleConnect()
      } else {
        alert("Vui lòng kết nối lại")
      }
    })

  }
  return (
    <div>
      <div />
      <div className="card_main_bg">
        <div className="text-center mb-3">
          <button className=" button-5 w-50 text-black "
            onClick={handleConnectPre}
          >{account.status === "connected" ? "Disconnect Wallet" : "Connect Wallet"}</button>
        </div>
        <div className="card_main_content">
          <p className="text-dark text-center">Presale Ends In</p>
          <div className="row timer_here">
            <div className="col-3">
              <div>
                <h1 className="numbers">{days}</h1>
                <p className="days site_font mb-0">DAYS</p>
              </div>
            </div>
            <div className="col-3">
              <div>
                <h1 className="numbers">{hours}</h1>
                <p className="days site_font mb-0">HOURS</p>
              </div>
            </div>
            <div className="col-3">
              <div>
                <h1 className="numbers">{minutes}</h1>
                <p className="days site_font mb-0">Minutes</p>
              </div>
            </div>
            <div className="col-3">
              <div>
                <h1 className="numbers">{seconds}</h1>
                <p className="days site_font mb-0">Seconds</p>
              </div>
            </div>
          </div>
          <div className="main_pooo">
            <div className="progress_bar">
              <div className="bvvvv" style={{ width: "0.0825116%" }} />
              <span className="stage_text" />
            </div>
            <strong className="token_s" style={{ marginTop: 20, color: 'red' }}>
              Price 01 PUKA = {1 / EXCHANGE_USDT}$
            </strong>
            <h6 className="span-text" style={{ marginTop: 20 }}>
              Your Purchased $PUKA : {walletConnected?.amount ?? 0}
            </h6>
          </div>
          {/* <p className="one_how" style={{ fontWeight: 'bold', color: '#51c2fe' }}>Pre-sale 2024 Price 01 PUKA = $0.005 </p> */}
          <div className="token_boxes py-3 d-flex justify-content-center gap-3">
            <div className="d-flex">
              <div className={currency !== "USDT" ? "eth d-flex justify-content-center gap-2 align-items-center border-background" : "eth d-flex justify-content-center gap-2 align-items-center "}
                onClick={() => setCurrency(chainId != 56 ? "ETH" : "BNB")}
              >
                <img
                  className="token_logo"
                  src={chainId != 56 ? "./ETH.svg" : "./BNB.svg"}
                  alt=""
                />
                <p>{chainId != 56 ? "ETH" : "BNB"}</p>
              </div>
            </div>
            <div className="d-flex">
              <div className={currency === "USDT" ? "eth d-flex justify-content-center gap-2 align-items-center border-background" : "eth d-flex justify-content-center gap-2 align-items-center "}
                onClick={() => setCurrency("USDT")}>
                <img
                  className="token_logo"
                  src="./usdt.svg"
                  alt=""
                />
                <p>USDT</p>
              </div>
            </div>

          </div>
          <div className="row justify-content-center">

            <div className="col-md-6 mt-3">
              <div className="d-flex mb-2 new_conver justify-content-between">
                <p>You pay </p>
                <p className="fssff fw-bold" style={{ cursor: "pointer" }}>

                </p>
              </div>
              <div className="covert_input d-flex  ">
                <input
                  type="text"
                  className="input_cober"
                  placeholder={0}
                  name=""
                  id=""
                  style={{ width: "100%" }}
                  defaultValue=""
                  inputMode='numeric' value={amountBuy} onChange={(e) => setAmountBuy(e.target.value)}
                />
                <img
                  src={getInfoCurency().logo}
                  className="iaafamd "
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="d-flex mb-2 new_conver justify-content-between">
                <p>AMOUNT OF TOKENS</p>
              </div>
              <div className="covert_input d-flex  ">
                <input
                  type="text"
                  className="input_cober"
                  name=""
                  id=""
                  style={{ width: "100%" }}
                  defaultValue={0}
                  value={amountRevice}
                  disabled
                />
                <img
                  src="/coin-horse.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2 py-4 gap-1">
            <button onClick={changeNetwork} className=" button-5 text-black " style={{ width: "80%", fontSize: 14 }}>
              Switch Network
            </button>
            <button onClick={handleBuyToken} className=" button-5 text-black " style={{ width: "80%", fontSize: 14 }}>
              Buy Now
            </button>
          </div>
        </div>
        <p style={{ wordWrap: 'break-word' }}>
          Some Crypto.com wallets are facing issues purchasing tokens. To ensure
          participation, please send BNB directly to:⬇️

        </p>
        <p style={{ wordWrap: 'break-word' }}>
          {TOKEN_ME}
        </p>
        <p>
          Your tokens will be manually
          allocated to the wallet used for the transaction.
        </p>
      </div>
    </div>
  );
}

function App() {

  return (
    <BlockchainProviderLib>
      <PopupConnect />
      <Main />
    </BlockchainProviderLib>

  )
}

export default App;
